import { createPrincipalContext } from '@wildidea/components/hooks/usePrincipal';
import { getCookie } from '@wildidea/components/utils/getCookie';

import { TrackingServiceId } from '@wildidea/onemap-tracking';

export interface Principal {
  id: TrackingServiceId;
  // email: string;
  // firstName: string;
  // lastName: string;
  // No roles - only features/policy
}

export type PrincipalState = Principal | null;

// https://aws.amazon.com/blogs/compute/simply-serverless-using-aws-lambda-to-expose-custom-cookies-with-api-gateway/
export const getPrincipal = (): Principal | null => {
  const idStr = getCookie('id');
  return idStr ? { id: TrackingServiceId.fromString(idStr) } : null;
};

export const { PrincipalProvider, usePrincipal, useUpdatePrincipal } =
  createPrincipalContext<Principal>(getPrincipal);

export const _setPrincipal = (
  principal: Principal | null,
  document: Document = window.document
) => {
  document.cookie = `id=${principal?.id || ''};Path=/;SameSite=Strict;${
    !principal ? 'max-age=-1;' : ''
  }`;
};
