import { LngLat } from '@wildidea/tools/utils/geospatial.js'

import { TrackingStatus } from './TrackingStatus.js'
import { DetailedGeoEvent, GeoEvent, GeoMessage } from './GeoEvent.js'
import { TrackingDevice } from './TrackingDevice.js'
import { ITrackingServiceId } from './TrackingService.js'
import { TrackingUser } from './TrackingUser.js'
import { TimeRangeFilter } from './TimeRange.js'
import { TrackSummary } from './Track.js'

export interface GetUserDataInput {
  user: ITrackingServiceId
  timeRange?: TimeRangeFilter
}

export interface GetUserMessagesInput extends GetUserDataInput {
  eventIds?: ITrackingServiceId[]
}

export interface GetUserTracksInput extends GetUserDataInput {
  trackIds?: string[]
}

export interface UserStatus extends TrackingUser {
  status: TrackingStatus & {
    /** The most recent event from any of the user's devices. */
    mostRecentEvent: DetailedGeoEvent
    /**
     * If the user is further than 1hr from emergency care, this will contain information that may
     * be useful in an emergency.
     */
    wilderness: WildernessStatus
    // TODO Responsible local authorities
    // authorities: Place[]
  }
  /** Detailed status on all of the user's devices. */
  devices: TrackingDeviceWithLastEvent[]
}

/**
 * This exists to extend the TrackingDevice data with computed statuses that are ephemeral.
 * It stores the `lastEvent` reference because putting a `lastEvent` in a `TrackingDevice` within a
 * `GeoEvent` would be circular.
 */
export type TrackingDeviceWithLastEvent = TrackingDevice<{
  /** Look within here to see last known position of this device and what time it was last seen. */
  mostRecentEvent: GeoEvent
}>

export interface NotInWildernessStatus {
  status: 'none'
}

export interface InWildernessStatus {
  /**
   * - `remote`: cannot be reached by car or > 5hr from emergency care
   * - `wilderness`: > 1hr from emergency care
   * - `none`: Within 1hr of emergecy care
   */
  status: 'remote' | 'wilderness'
  /** May be empty if the user is 'remote'. */
  nearestEmergencyCare: PlaceWithDriveEstimate[]
  /** May be empty if the user is 'remote'. */
  nearestRoad: LngLat | null
}

export type WildernessStatus = NotInWildernessStatus | InWildernessStatus

export interface PlaceWithDriveEstimate {
  place: Place
  driveStart: LngLat
  driveEstimate: DriveEstimate
}

export interface DriveEstimate {
  /** Driving distance from the road nearest to the user's current position, in seconds. */
  duration: number
  /** Driving time from the road nearest to the user's current position, in meters. */
  distance: number
  attribution: DirectionsProvider
}

export interface Place {
  name: string
  address: string
  location: LngLat
  providerUrl: string
  attribution: PlacesSearchProvider
}

export enum PlacesSearchProvider {
  Google = 'google'
}

export enum DirectionsProvider {
  Mapbox = 'mapbox'
}

export const EVENT_TYPE_USERSTATUS_UPDATED = 'UserStatus:Updated'
export interface UserStatusUpdatedEventDetail {
  user: UserStatus
}

export interface GetUserMessagesResponse {
  messages: GeoMessage[]
}

export interface GetUserTracksResponse {
  tracks: TrackSummary[]
}
