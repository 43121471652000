import { getTilesetEndpoint } from './getTilesetEndpoint.js'
import { getUserMessages } from './getUserMessages.js'
import { getUserStatus } from './getUserStatus.js'
import { getUserTracks } from './getUserTracks.js'
import { UserDataServiceApi } from '../src/domain/UserDataServiceApi.js'

export class VectorTileService {
  constructor() {}
  getTilesetEndpoint = getTilesetEndpoint
}

export class UserDataService implements UserDataServiceApi {
  constructor() {}

  getStatus = getUserStatus
  getMessages = getUserMessages
  getTracks = getUserTracks
}

export * from './model.js'
