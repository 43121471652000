export enum TrackingService {
  InReach = 'inreach'
}

export const isTrackingService = (str: string): str is TrackingService =>
  Object.values(TrackingService).includes(str as TrackingService)

/**
 * Represents a unique identifier within a third-party service.
 *
 * We aggregate data across multiple services, so we need to avoid collision between unique
 * identifiers within the two systems. This is a Compound ID that achieves that.
 */
export interface ITrackingServiceId {
  service: TrackingService
  id: string
  toString: unknown
}

const tsid: unique symbol = Symbol('tsid')
/**
 * Represents a unique identifier within a third-party service.
 *
 * We aggregate data across multiple services, so we need to avoid collision between unique
 * identifiers within the two systems. This is a Compound ID that achieves that.
 *
 * This class includes methods to aid in serialization.
 */
export class TrackingServiceId implements ITrackingServiceId {
  service: TrackingService
  id: string;
  [tsid] = tsid
  constructor(serviceId: ITrackingServiceId) {
    this.service = serviceId.service
    this.id = serviceId.id.toLowerCase()
  }
  static fromString(idStr: string) {
    const [service, id] = idStr.split('-')
    if (!isTrackingService(service)) {
      throw new Error(`The given service prefix "${service}" is not a recognized TrackingService.`)
    }
    return new TrackingServiceId({ service, id })
  }
  toString() {
    return `${this.service}-${this.id}`
  }
  equals(id: ITrackingServiceId) {
    return id.id === this.id && id.service === this.service
  }
}
